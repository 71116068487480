import { authenticationGuard } from '@guards/authentication.guard';
import { NaxRoutes } from '@models/routes.model';
import { resetLoggedStatesResolver } from './resolvers/reset-logged-states.resolver';

export const APP_ROUTES: NaxRoutes = [
  {
    path: '',
    canMatch: [authenticationGuard],
    loadChildren: () => import('./pages/main/main.routes'),
  },
  {
    path: '',
    resolve: [resetLoggedStatesResolver],
    loadChildren: () => import('./pages/authentication/authentication.routes'),
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];
