import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { delayTimeByUrl } from '@constants/delay-time-by-url';
import { environment } from '@environments/environment';
import { SnackbarService } from '@services/snackbar.service';
import {
  Observable,
  Subject,
  filter,
  finalize,
  interval,
  merge,
  takeUntil,
  tap,
} from 'rxjs';

export const serverDelayNotificationInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const snackbarService = inject(SnackbarService);
  const endRequest$ = new Subject<null>();

  const request$ = next(request).pipe(
    finalize(() => completeSubject(endRequest$)),
  );

  const serverDelayTimeByUrl = delayTimeByUrl.find((urlDelaySpecification) => {
    return (
      !!urlDelaySpecification.regExpUrl.test(request.url) &&
      request.method === urlDelaySpecification.method
    );
  });

  const serverDelayMessage =
    serverDelayTimeByUrl?.message ?? 'SERVER_DELAY_WARNING';

  const serverDelay =
    serverDelayTimeByUrl?.delay ?? environment.server_delay_in_ms;

  const delay =
    serverDelay > environment.server_delay_in_ms
      ? serverDelay
      : environment.server_delay_in_ms;

  const serverDelayMessageParams = {
    url: request.url,
    time: delay,
  };

  const interval$ = interval(delay).pipe(
    takeUntil(endRequest$),
    tap(() => {
      snackbarService.add({
        message: serverDelayMessage,
        messageParams: serverDelayMessageParams,
        color: 'warning',
      });
      completeSubject(endRequest$);
    }),
  );

  return merge(request$, interval$).pipe(
    filter((w): w is HttpEvent<unknown> => typeof w !== 'number'),
  );
};

const completeSubject = (subject: Subject<null>): void => {
  subject.next(null);
  subject.complete();
};
