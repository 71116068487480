import * as L from 'leaflet';

import 'leaflet-draw';
import 'leaflet.markercluster';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err, L),
);
