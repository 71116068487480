<nax-environment-indicator *naxFeatureFlag="'ENVIRONMENT_INDICATOR'" />
<router-outlet />
<nax-snackbar-container />
<nax-dialog
  #defaultDialog
  [type]="dialogInfo().type ?? 'dialog'"
  [(isOpen)]="openDialog"
  [closeClickingOutside]="dialogInfo().canCloseClickingOutside ?? true">
  <ng-container dialog-title>
    @if (dialogInfo().title; as title) {
      <h2 class="break-word">
        {{ title | naxTranslate }}
      </h2>
    }
  </ng-container>
  @if (dialogInfo().message) {
    @for (message of dialogInfo().message; track message) {
      <p class="body--medium">{{ message | naxTranslate }}</p>
    }
  }
  <ng-container dialog-footer>
    @if (!(dialogInfo().showCancelButton === false)) {
      <nax-cta
        appearance="text"
        (click)="dialogInfo().cancelAction?.(); defaultDialog.closeDialog()"
        [label]="dialogInfo().cancelLabelButton ?? 'CANCEL'" />
    }
    @if (dialogInfo().action) {
      <nax-cta
        [label]="dialogInfo().confirmLabelButton ?? 'CONFIRM'"
        (click)="dialogInfo().action?.(); defaultDialog.closeDialog()" />
    }
  </ng-container>
</nax-dialog>

<!-- ↓↓↓ Provisional solution, must be replaced when is designed and defined  -->
@if (isOffline()) {
  <nax-icon
    class="nax-root__offline-indicator | portrait-only bg--surface elevation-level1"
    icon="wifi_off" />
}
