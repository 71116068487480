import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorCodeType, errorCodes } from '@constants/error-codes';
import { environment } from '@environments/environment';
import { Nullable } from '@models/nullable.model';
import { SnackbarService } from '@services/snackbar.service';

interface ServerErrorBody {
  detail: {
    loc: [string, string, string];
    msg: string;
    type: string;
  }[];
}

export const httpErrorNotification = (
  snackbarService: SnackbarService,
  error: unknown,
): void => {
  if (!(error instanceof HttpErrorResponse)) return;

  const message = errorMessageFactory(error);

  const isUnauthorizedError =
    error.status === HttpStatusCode.Unauthorized && !message;
  const hasNoMessageInProduction =
    ['BETA', 'PRO'].includes(environment.name) && !message;
  const skipNotification = isUnauthorizedError || hasNoMessageInProduction;

  if (skipNotification) return;

  snackbarService.add({
    message: message ?? 'SERVER_ERROR',
    messageParams: {
      status: error.status,
      url: error.url,
    },
    color: 'error',
  });
};

const errorMessageFactory = (error: HttpErrorResponse): Nullable<string> => {
  return (
    getLocalErrorMessage(error.url, error.status) ??
    getServerErrorMessage(error)
  );
};

const getLocalErrorMessage = (
  url: Nullable<string>,
  status: number,
): Nullable<ErrorCodeType['message']> => {
  return (
    errorCodes.find((e) => url && e.code === status && e.regExpUrl.test(url))
      ?.message ?? null
  );
};

const getServerErrorMessage = (error: HttpErrorResponse): Nullable<string> => {
  if (
    !error.url?.includes(environment.server_url) ||
    error.status !== HttpStatusCode.BadRequest
  )
    return null;

  return (
    (error.error as ServerErrorBody | undefined)?.detail.at(0)?.type ?? null
  );
};
