import { Component, HostBinding } from '@angular/core';
import { environment } from '@environments/environment';
import { ServerSubdomain } from '@models/environment.model';
import { IconComponent } from '../icon/icon.component';
@Component({
  selector: 'nax-environment-indicator',
  standalone: true,
  templateUrl: './environment-indicator.component.html',
  imports: [IconComponent],
})
export class EnvironmentIndicatorComponent {
  @HostBinding('class')
  get class(): string {
    return this.handleClasses();
  }
  protected environment = environment;
  protected apiName = environment.server_url
    .replace('https://', '')
    .replace('.naxsolutions.com', '') as ServerSubdomain;

  private handleClasses(): string {
    const classes = ['nax-environment-indicator'];

    classes.push(this.color());

    return classes.join(' ');
  }

  private color = (): string => {
    if (environment.name === 'DEV' && this.apiName === 'dev-api')
      return 'nax-environment-indicator--dev-dev-api';
    if (environment.name === 'DEV.PRO' && this.apiName === 'beta-api')
      return 'nax-environment-indicator--dev-pro-beta-api';
    if (environment.name === 'RELEASE' && this.apiName === 'beta-api')
      return 'nax-environment-indicator--dev-release-api';
    return '';
  };
}
