import { Component, HostBinding, input, OnInit, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Snackbar } from '@services/snackbar.service';
import { filter, interval } from 'rxjs';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { CtaComponent } from '../../cta/cta.component';

@Component({
  selector: 'nax-snackbar',
  standalone: true,
  imports: [CtaComponent, TranslatePipe],
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent implements OnInit {
  @HostBinding('class') get class(): string {
    return this.handleClasses();
  }

  private closeTime = Date.now();
  private interval = interval(100).pipe(
    takeUntilDestroyed(),
    filter(() => this.closeTime < Date.now()),
  );

  title = input<Snackbar['title']>();
  message = input.required<Snackbar['message']>();
  messageParams = input<Snackbar['messageParams']>();
  duration = input(3000);

  clickClose = output();

  ngOnInit(): void {
    this.closeTime = Date.now() + this.duration();
    this.interval.subscribe(() => this.clickClose.emit());
  }

  protected onClickClose(): void {
    this.clickClose.emit();
  }

  private handleClasses(): string {
    const classes = [
      'bg--surface-container-highest rounded--extra-small elevation-level4 pointer-events-all  align-center gap-2 py-2 px-4 w-80',
    ];
    if (this.title()) classes.push('flex-column');
    else classes.push('flex');

    return classes.join(' ');
  }
}
